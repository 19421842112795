import React from 'react'

export default function footer() {
    return (
        <footer>
            © {new Date().getFullYear()}, Phát triển: devnerd
            {` `}
            <a href="mailto: okutkd@gmail.com">okutkd@gmail.com</a>
        </footer>
    )
}
